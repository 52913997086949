import { Divider, TextField } from "@mui/material";
import { styled } from "@mui/material/styles";

export const StyledDivider = styled(Divider)({
  marginTop: "10px",
});

export const StyledDiv = styled("div")({
  display: "flex",
  marginTop: "5px",
});

export const StyledTextField = styled(TextField)({
  width: "100%",
});

export const SubTitle = styled("p")({
  fontFamily: "EMprint",
  fontWeight: "600",
  fontSize: "16px",
  color: "#545459",
  textTransform: "none",
  textAlign: "left",
  paddingTop: "10px",
});

export const SubSectionTitle = styled("p") ({
  fontFamily: "EMprint",
  fontWeight: "600",
  fontSize: "16px",
  color: "#545459",
  textTransform: "none",
  textAlign: "left",
  paddingTop: "20px",
});

export const defaultWorkRequest = {
  statusName: "Submitted",
  description: "",
  requestedByEmail: "",
  createdByEmail: null,
  createdDate: null,
  lastModifiedByEmail: null,
  lastModifiedDate: null,
  requestedCompletionDate: null,
  lastAcceptableCompletionDate: null,
  assignedToEmail: "",
  assignedDate: null,
  completedDate: null,
  completedByEmail: null,
  priorityName: "Normal",
  priorityReason: "",
  businessUnitName: null,
  comments: "",
  facilityName: null,
  isNonChemical: false,
  chargeCode:  "",
};

export const defaultErrorsInformation = {
  description: null,
  businessUnitName: null,
  requestedCompletionDate: null,
  lastAcceptableCompletionDate: null,
  requestedByEmail: null,
}

export const defaultShippingAddress = {
  recipientName: '',
  company: '',
  department: '',
  address1: '',
  city: '',
  state: '',
  country: '',
  postalCode: '',
  phoneNumber1: '',
  emailAddress: '',
};

export const defaultShippingRequest = {
  requestedDeliveryDate: null,
  shippingCarrierID: null,
  shippingAddress: null,
  is3PTesting: false,
  shippingReasonName: null,
  isPrepaid: null,
  acknowledge: false,
  isTSCAReview: false,
  shippingItems: [],
};

export const defaultErrorsShippingInformation = {
  recipientName: null,
  phoneNumber1: null,
  country: null,
  postalCode: null,
  state: null,
  city: null,
  address1: null,
  requestedDeliveryDate: null,
  shippingCarrierID: null,
  shippingReasonName: null,
  isPrepaid: null,
}

export const defaultShippingItem = {
  thisRequest: null,
  containerID: null,
  thisContainer: null,
  description: null,
  dotHazardClass: null,
  exportValue: null,
  flashPointInC: null,
  quantity: null,
  quantityUoMName: null,
  quantityUoM: null,
  comments: null,
  sampleName: null,
  blindCodeDescription: null,
};

export const labelProp: Record<string, string> = {
  description: 'Description',
  facilityName: 'Facility',
  businessUnitName: 'Business Unit',
  requestedCompletionDate: 'Requested Completion Date',
  lastAcceptableCompletionDate: 'Last Acceptable Date',
  requestedByEmail: 'Requester Email',
  priorityReason: 'Priority Reason',
  recipientName: 'Recipient\'s Name',
  phoneNumber1: 'Phone #',
  country: 'Country',
  postalCode: 'Zip',
  state: 'State',
  city: 'City',
  address1: 'Street Address',
  requestedDeliveryDate: 'Delivery Required By',
  shippingCarrierID: 'Ship Via',
  shippingReasonName: 'Reason for Shipment',
  isPrepaid: 'Shipment Payment',
}

export const CONTAINERING_METHOD_ENUM = {
  MIDAS: "midas",
  NON_SPLIT: "nonsplit",
};

export const RECOMMENDATIONS_ERROR_MESSAGES = {
  NO_CONTAINERS: 'No containers found. Please hit "Get Recommendations" button',
  INVALID_AMOUNT:
    "Invalid amount available. Container does not have enough volume for all tests.",
  REASK_RECOMMENDATIONS:
    "Container and/or Methods has been changed. Please get new recommendations.",
};
