import { useEffect, useState } from "react";
import {
  SubSectionTitle,
  SubTitle,
  defaultBlindCoding,
} from "./constants";
import { SDSFormInput } from "./SDSFormInput";
import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";
import { removeMatchingProperties } from "../../../../global";

const BlindCoding = ({ commonProps, disabled }) => {
  const { data, setData, setErrors, errors } = commonProps;
  const [blindCodeForCommericalProductDisabled, setBlindCodeForCommericalProductDisabled] = useState(false);
  const [blindCodeForExperimentalProductDisabled, setBlindCodeForExperimentalProductDisabled] = useState(false);
  const [blindCodeForNonEmDisabled, setBlindCodeForNonEmDisabled] = useState(false);

  useEffect(() => {
    return () => {
      setData((prevData) => (removeMatchingProperties(prevData, defaultBlindCoding)))
    };
  }, [setData]);

  const handleCheckedOption = (checked, option) => {
    if (option === 'blindCodeForCommericalProduct') {
      if (checked) {
        setBlindCodeForExperimentalProductDisabled(true);
        data.blindCodeForExperimentalProduct = false;
        data.experimentalDetails = "";

        setBlindCodeForNonEmDisabled(true);
        data.blindCodeForNonEM = false;
      } else {
        setBlindCodeForExperimentalProductDisabled(false);
        setBlindCodeForNonEmDisabled(false);
        data.commericalProductDetails = "";
      }
    }

    if (option === 'blindCodeForExperimentalProduct') {
      if (checked) {
        setBlindCodeForCommericalProductDisabled(true);
        data.blindCodeForCommericalProduct = false;
        data.commericalProductDetails = "";

        setBlindCodeForNonEmDisabled(true);
        data.blindCodeForNonEM = false;
      } else {
        setBlindCodeForCommericalProductDisabled(false);
        setBlindCodeForNonEmDisabled(false);
        data.experimentalDetails = "";
      }
    }

    if (option === 'blindCodeForNonEM') {
      if (checked) {
        setBlindCodeForCommericalProductDisabled(true);
        data.blindCodeForCommericalProduct = false;
        data.commericalProductDetails = "";

        setBlindCodeForExperimentalProductDisabled(true);
        data.blindCodeForExperimentalProduct = false;
        data.experimentalDetails = "";
      } else {
        setBlindCodeForCommericalProductDisabled(false);
        setBlindCodeForExperimentalProductDisabled(false);
      }
    }
  }

  return (
    <div>
      <SubSectionTitle>SDS For Blind Coding</SubSectionTitle>
      <div style={{ display: "flex", gap: "15px", alignItems: "baseline" }}>
        <SDSFormInput
          {...commonProps}
          disabled={disabled}
          label="Provide desired blind code name"
          property="sdsBlindCodeName"
        />
      </div>

      <div style={{ display: "flex", gap: "15px", alignItems: "baseline" }}>
        <FormControlLabel
          control={<Checkbox />}
          disabled={disabled || blindCodeForCommericalProductDisabled}
          checked={data.blindCodeForCommericalProduct}
          onChange={(e) => {
            if (e.target.checked) {
              setErrors({
                ...errors,
                commericalProductDetails: null
              })
            } else {
              delete errors.commericalProductDetails;
            }

            handleCheckedOption(e.target.checked, 'blindCodeForCommericalProduct');

            setData((prevData) => ({
              ...prevData,
              blindCodeForCommericalProduct: e.target.checked
            }));
          }}
          label={<>Do you need a blind code created for an ExxonMobil Commercial Product (including all TDM)?</>}
        />
      </div>
      <div style={{ display: "flex", gap: "15px", alignItems: "baseline" }}>
        <SDSFormInput
          {...commonProps}
          disabled={disabled || !data.blindCodeForCommericalProduct}
          label="If Yes, please provide the ProMIS Technical Spec. Code to be blind coded"
          property="commericalProductDetails"
          required={!!data.commericalProductDetails}
        />
      </div>

      <div style={{ display: "flex", gap: "15px", alignItems: "baseline" }}>
        <FormControlLabel
          control={<Checkbox />}
          disabled={disabled || blindCodeForExperimentalProductDisabled}
          checked={data.blindCodeForExperimentalProduct}
          onChange={(e) => {
            if (e.target.checked) {
              setErrors({
                ...errors,
                experimentalDetails: null
              })
            } else {
              delete errors.experimentalDetails;
            }

            handleCheckedOption(e.target.checked, 'blindCodeForExperimentalProduct');

            setData((prevData) => ({
              ...prevData,
              blindCodeForExperimentalProduct: e.target.checked
            }));
          }}
          label={<>Do you need a blind code created for an ExxonMobil Experimental Product (including all TDM)?</>}
        />
      </div>
      <div style={{ display: "flex", gap: "15px", alignItems: "baseline" }}>
        <SDSFormInput
          {...commonProps}
          disabled={disabled || !data.blindCodeForExperimentalProduct}
          label="If Yes, please provide the name of the experimental SDS created by EMBSI"
          property="experimentalDetails"
          required={!!data.blindCodeForExperimentalProduct}
        />
      </div>

      <div style={{ display: "flex", gap: "15px", alignItems: "baseline" }}>
        <FormControlLabel
          control={<Checkbox />}
          disabled={disabled || blindCodeForNonEmDisabled}
          checked={data.blindCodeForNonEM}
          onChange={(e) => {
            handleCheckedOption(e.target.checked, 'blindCodeForNonEM');
            setData((prevData) => ({
              ...prevData,
              blindCodeForNonEM: e.target.checked
            }));
          }}
          label={<>Do you need a blind code for a non-ExxonMobil Product? If so, please attach the product's SDS in the file attachments section.</>}
        />
      </div>
      <SubTitle>Regions where product will be used:</SubTitle>
      <FormGroup row>
        <FormControlLabel
          control={<Checkbox />}
          disabled={disabled}
          checked={data.isNorthAmerica}
          onChange={(e) => setData((prevData) => ({
            ...prevData,
            isNorthAmerica: e.target.checked
          }))}
          label={<>North America</>}
        />
        <FormControlLabel
          control={<Checkbox />}
          disabled={disabled}
          checked={data.isSouthAmerica}
          onChange={(e) => setData((prevData) => ({
            ...prevData,
            isSouthAmerica: e.target.checked
          }))}
          label={<>South America</>}
        />
        <FormControlLabel
          control={<Checkbox />}
          disabled={disabled}
          checked={data.isEurope}
          onChange={(e) => setData((prevData) => ({
            ...prevData,
            isEurope: e.target.checked
          }))}
          label={<>Europe</>}
        />
        <FormControlLabel
          control={<Checkbox />}
          disabled={disabled}
          checked={data.isAfrica}
          onChange={(e) => setData((prevData) => ({
            ...prevData,
            isAfrica: e.target.checked
          }))}
          label={<>Africa/Middle East</>}
        />
        <FormControlLabel
          control={<Checkbox />}
          disabled={disabled}
          checked={data.isAsiaPacific}
          onChange={(e) => setData((prevData) => ({
            ...prevData,
            isAsiaPacific: e.target.checked
          }))}
          label={<>Asia Pacific</>}
        />
      </FormGroup>
      <div style={{ display: "flex", gap: "15px", alignItems: "baseline" }}>
        <SDSFormInput
          {...commonProps}
          disabled={disabled}
          label="If country-specific SDSes are required, please specify"
          property="countrySpecificInfo"
        />
      </div>
    </div>
  );
};

export default BlindCoding;
