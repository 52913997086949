import { backlogViewType } from "./constants";
import GridRequests from "./gridRequests";

const MyRequests = ({ ...props }) => {
  const { reloadData } = props;
  return (
    <>
      <h3>
        <GridRequests type={backlogViewType.myRequests} reloadData={reloadData} />
      </h3>
    </>
  );
}

export default MyRequests;