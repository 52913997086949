import { useContext, useEffect, useState } from "react"
import { Box, Checkbox, Grid, FormControlLabel } from "@mui/material";
import { GlobalButton, GlobalSecondaryButton } from "../../../styles";
import { applyFiltersToArray, hasRole, Roles, exportToCsv } from "../../../../global";
import { GridRender } from "./GridRender"
import UserContext from "../../../../context/UserContext"
import FilterMenu from "../../../../components/FilterMenu";
import Requests from "../../../../api/SDSRequest/SDSRequest";

const CompletedStatus = 'Completed';
const CancelledStatus = 'Cancelled';
const GridColumns = [
  { id: "", label: "", notSortable: true },
  { id: "id", label: "Request ID" },
  { id: "requesterByEmail", label: "Requested For" },
  { id: "sdsRequestType.description", label: "Request Type" },
  { id: "createdDate", label: "Date Created" },
  { id: "requestedCompletionDate", label: "Required Date" },
  { id: "requestStatusName", label: "Status" },
  { id: "project.name", label: "Project" },
  { id: "description", label: "Description" },
];

const ProcessSDS = ({ ...props }) => {
  const [showCompletedRequests, setShowCompletedRequests] = useState(false);
  const [showCancelledRequests, setShowCancelledRequests] = useState(false);
  const [showMyAssignedRequests, setShowMyAssignedRequests] = useState(false);
  const [result, setResult] = useState();
  const [originalResult, setOriginalResult] = useState();
  const [loading, setLoading] = useState(true);
  const [filteringOpen, setFilteringOpen] = useState(false);
  const [filters, setFilters] = useState([{ name: null, displayName: null, operator: null, enumValues: [], value: '' }])
  const [filteredRequests, setFilteredRequests] = useState([])
  const [reloadData] = useState(false)
  const currentUser = useContext(UserContext);
  const roles = currentUser?.idTokenClaims.roles;
  const canEdit = hasRole(Roles.SdsProcessor, roles) || hasRole(Roles.Developer, roles);

  const getRequest = async () => {
    setLoading(true)
    const data = await Requests.getAll()
    if (data) {
      setResult(data.filter(s =>
        s.requestStatusName !== CompletedStatus &&
        s.requestStatusName !== CancelledStatus))
      setOriginalResult(data)
    }
    setLoading(false)
  }

  useEffect(() => {
    getRequest();
  }, [reloadData]);

  useEffect(() => {
    let updatedResult = [...result];
    if (showMyAssignedRequests) {
      updatedResult = result.filter(s => s.assignedToEmail === currentUser.username);
    }
    var filteredArray = applyFiltersToArray(filters, updatedResult);
    setFilteredRequests(filteredArray)
  }, [currentUser.username, filters, result, showMyAssignedRequests]);

  const filterClick = (event) => {
    setFilteringOpen(true);
  }

  const clearFiltersClick = (event) => {
    setFilters([{ name: null, displayName: null, operator: null, enumValues: [], value: null }])
  }

  function closeFiltering() {
    setFilteringOpen(false);
  }

  function applyFilters() {
    setFilteringOpen(false);
  }

  function handleExportToCsv() {
    const data = filteredRequests.map(row => {
      const transformedRow = {};
      GridColumns.slice(1).forEach(col => {
        const keys = col.id.split('.');
        let value = row;
        keys.forEach(key => {
          value = value ? value[key] : '';
        });
        transformedRow[col.label] = value;
      });
      return transformedRow;
    });

    exportToCsv(data, 'SDS_Requests.csv')
  }

  const handleCheckBoxFilter = (checked, status) => {
    let updatedResult = [...originalResult];

    let completed = showCompletedRequests;
    let cancelled = showCancelledRequests;

    if (status === CompletedStatus) {
      setShowCompletedRequests(checked);
      completed = checked;
    } else if (status === CancelledStatus) {
      setShowCancelledRequests(checked);
      cancelled = checked;
    }

    if (!completed && !cancelled) {
      updatedResult = originalResult.filter(s => s.requestStatusName !== CompletedStatus && s.requestStatusName !== CancelledStatus);
    } else if (completed && !cancelled) {
      updatedResult = originalResult.filter(s => s.requestStatusName !== CancelledStatus);
    } else if (!completed && cancelled) {
      updatedResult = originalResult.filter(s => s.requestStatusName !== CompletedStatus);
    } else if (completed && cancelled) {
      updatedResult = [...originalResult];
    }

    if (status === "AssignedToMe")
      setShowMyAssignedRequests(checked);

    setResult(updatedResult);
  };

  const filterOptions = [
    { name: "id", displayName: "Request ID", type: "string", enumValues: [] },
    { name: "requesterByEmail", displayName: "Requested For", type: "string", enumValues: [] },
    { name: "sdsRequestType.description", displayName: "Request Type", type: "string", enumValues: [] },
    { name: "createdDate", displayName: "Date Created", type: "date", enumValues: [] },
    { name: "requestedCompletionDate", displayName: "Required Date", type: "date", enumValues: [] },
    { name: "requestStatusName", displayName: "Status", type: "string", enumValues: [] },
    { name: "project.name", displayName: "Project", type: "string", enumValues: [] },
    { name: "description", displayName: "Description", type: "string", enumValues: [] },
  ]

  return (
    <>
      <Grid container justifyContent="right" style={{ marginTop: "10px" }}>
        <FormControlLabel
          sx={{ justifyContent: "right" }}
          control={<Checkbox />}
          onChange={(e) => {
            handleCheckBoxFilter(e.target.checked, CompletedStatus)
          }}
          checked={showCompletedRequests}
          label="Show Completed Requests"
        />
        <FormControlLabel
          sx={{ justifyContent: "right" }}
          control={<Checkbox />}
          onChange={(e) => {
            handleCheckBoxFilter(e.target.checked, CancelledStatus)
          }}
          checked={showCancelledRequests}
          label="Show Cancelled Requests"
        />
        <FormControlLabel
          sx={{ justifyContent: "right" }}
          control={<Checkbox />}
          onChange={(e) => {
            handleCheckBoxFilter(e.target.checked, "AssignedToMe")
          }}
          checked={showMyAssignedRequests}
          label="Show Only Requests Assigned To Me"
        />
        <Box display="flex" alignItems={"center"} marginLeft="auto" marginTop="-.8rem">
          <GlobalButton
            style={{ marginRight: "1rem" }}
            variant="contained"
            onClick={() => filterClick()}
          >Filters</GlobalButton>

          {!(filters[0].name === null) &&
            <GlobalSecondaryButton
              style={{ marginRight: "1rem" }}
              variant="contained"
              onClick={() => clearFiltersClick()}
            >Clear Filters</GlobalSecondaryButton>}

          {(filteredRequests?.length) &&
            <GlobalSecondaryButton
              variant="contained"
              onClick={() => handleExportToCsv()}
            >Export CSV</GlobalSecondaryButton>}
        </Box>
      </Grid>
      <br />
      <GridRender
        gridColumns={GridColumns}
        canEdit={canEdit}
        reloadList={getRequest}
        requests={filteredRequests}
        showCompletedRequests={showCompletedRequests}
        showCancelledRequests={showCancelledRequests}
        loading={loading}
        currentUser={currentUser}
      />
      <FilterMenu
        open={filteringOpen}
        setOpen={setFilteringOpen}
        applyBtnAction={applyFilters}
        cancelButtonAction={closeFiltering}
        filteringInfo={filterOptions}
        appliedFilters={filters}
        setAppliedFilters={setFilters}
      />
    </>
  )
}

export default ProcessSDS;