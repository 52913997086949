import { useContext, useEffect, useState } from "react"
import { Box, Checkbox, Grid, FormControlLabel } from "@mui/material";
import { GlobalButton, GlobalSecondaryButton } from "../../../styles";
import { applyFiltersToArray, exportToCsv } from "../../../../global";
import { GridRender } from "./gridRender"
import UserContext from "../../../../context/UserContext"
import FilterMenu from "../../../../components/FilterMenu";
import Requests from "../../../../api/SDSRequest/SDSRequest";
import Project from "../../../../api/Admin/Project";
import { backlogViewType, requestStatusOptions } from "./constants";

const GridColumns = [
  { id: "", label: "", notSortable: true },
  { id: "id", label: "Request ID" },
  { id: "requesterByEmail", label: "Requested For" },
  { id: "sdsRequestType.description", label: "Request Type" },
  { id: "createdDate", label: "Date Created" },
  { id: "requestedCompletionDate", label: "Required Date" },
  { id: "lastAcceptableCompletionDate", label: "Last Acceptable Date" },
  { id: "requestStatusName", label: "Status" },
  { id: "project.name", label: "Project" },
  { id: "description", label: "Description" },
];

const GridRequests = ({ type, reloadData }) => {

  const [showCompletedRequests, setShowCompletedRequests] = useState(false);
  const [showCancelledRequests, setShowCancelledRequests] = useState(false);
  const [result, setResult] = useState();
  const [originalResult, setOriginalResult] = useState();
  const [userProjects, setUserProjects] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filteringOpen, setFilteringOpen] = useState(false);
  const [filters, setFilters] = useState([{ name: null, displayName: null, operator: null, enumValues: [], value: '' }]);
  const [filteredRequests, setFilteredRequests] = useState([]);

  const currentUser = useContext(UserContext);

  const baseRequestFilter = (item, type, currentUser, userProjects) => {
    const hasProjects = userProjects.includes(item.project.name);
    const assignedRequests = type === backlogViewType.myRequests && item.requesterByEmail === currentUser;
    return hasProjects && (type === backlogViewType.allRequests || assignedRequests);
  };

  const getRequest = async () => {
    const userProj = userProjects?.length > 0
      ? userProjects
      : await getUserProjects();

    const data = await Requests.getAll();
    if (data) {
      setOriginalResult(data.filter(s => baseRequestFilter(s, type, currentUser.username, userProj)));
      setResult(data.filter(s =>
        s.requestStatusName !== requestStatusOptions.completed &&
        s.requestStatusName !== requestStatusOptions.cancelled &&
        baseRequestFilter(s, type, currentUser.username, userProj)
      ));
    }
  };

  const getUserProjects = async () => {
    const data = (await Project
      .getByUser(currentUser.username))
      .map(project => project.name);

    setUserProjects(data);
    return data;
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      await getUserProjects();
      await getRequest();
      setLoading(false);
    }
    fetchData();
    // eslint-disable-next-line
  }, [reloadData]);

  useEffect(() => {
    var filteredArray = applyFiltersToArray(filters, result)
    setFilteredRequests(filteredArray)
  }, [filters, result]);

  const filterClick = (event) => {
    setFilteringOpen(true);
  }

  const clearFiltersClick = (event) => {
    setFilters([{ name: null, displayName: null, operator: null, enumValues: [], value: null }])
  }

  function closeFiltering() {
    setFilteringOpen(false);
  }

  function applyFilters() {
    setFilteringOpen(false);
  }

  function handleExportToCsv() {
    const data = filteredRequests.map(row => {
      const transformedRow = {};
      GridColumns.slice(1).forEach(col => {
        const keys = col.id.split('.');
        let value = row;
        keys.forEach(key => {
          value = value ? value[key] : '';
        });
        transformedRow[col.label] = value;
      });
      return transformedRow;
    });

    exportToCsv(data, `SDS_Requests_Backlog.csv`)
  }

  const handleCheckBoxFilter = (checked, status) => {
    let updatedResult = [...originalResult.filter(item =>
      baseRequestFilter(item, type, currentUser.username, userProjects))];

    let completed = showCompletedRequests;
    let cancelled = showCancelledRequests;

    if (status === requestStatusOptions.completed) {
      setShowCompletedRequests(checked);
      completed = checked;
    } else if (status === requestStatusOptions.cancelled) {
      setShowCancelledRequests(checked);
      cancelled = checked;
    }

    if (!completed && !cancelled) {
      updatedResult = originalResult.filter(s => s.requestStatusName !== requestStatusOptions.completed && s.requestStatusName !== requestStatusOptions.cancelled);
    }
    else if (completed && !cancelled) {
      updatedResult = originalResult.filter(s => s.requestStatusName !== requestStatusOptions.cancelled);
    }
    else if (!completed && cancelled) {
      updatedResult = originalResult.filter(s => s.requestStatusName !== requestStatusOptions.completed);
    }
    else if (completed && cancelled) {
      updatedResult = [...originalResult];
    }

    setResult(updatedResult);
  };

  const filterOptions = [
    { name: "id", displayName: "Request ID", type: "string", enumValues: [] },
    { name: "requesterByEmail", displayName: "Requested For", type: "string", enumValues: [] },
    { name: "sdsRequestType.description", displayName: "Request Type", type: "string", enumValues: [] },
    { name: "createdDate", displayName: "Date Created", type: "date", enumValues: [] },
    { name: "requestedCompletionDate", displayName: "Required Date", type: "date", enumValues: [] },
    { name: "lastAcceptableCompletionDate", displayName: "Last Acceptable Date", type: "date", enumValues: [] },
    { name: "requestStatusName", displayName: "Status", type: "string", enumValues: [] },
    { name: "project.name", displayName: "Project", type: "string", enumValues: [] },
    { name: "description", displayName: "Description", type: "string", enumValues: [] },
  ]

  return (
    <>
      <Grid container justifyContent="right" style={{ marginTop: "10px" }}>
        <FormControlLabel
          sx={{ justifyContent: "right" }}
          control={<Checkbox />}
          onChange={(e) => {
            handleCheckBoxFilter(e.target.checked, requestStatusOptions.completed)
          }}
          checked={showCompletedRequests}
          label="Show Completed Requests"
        />
        <FormControlLabel
          sx={{ justifyContent: "right" }}
          control={<Checkbox />}
          onChange={(e) => {
            handleCheckBoxFilter(e.target.checked, requestStatusOptions.cancelled)
          }}
          checked={showCancelledRequests}
          label="Show Cancelled Requests"
        />
        <Box display="flex" alignItems={"center"} marginLeft="auto" marginTop="-.8rem">
          <GlobalButton
            style={{ marginRight: "1rem" }}
            variant="contained"
            onClick={() => filterClick()}
          >Filters</GlobalButton>

          {!(filters[0].name === null) &&
            <GlobalSecondaryButton
              style={{ marginRight: "1rem" }}
              variant="contained"
              onClick={() => clearFiltersClick()}
            >Clear Filters</GlobalSecondaryButton>}

          {(filteredRequests?.length > 0) &&
            <GlobalSecondaryButton
              variant="contained"
              onClick={() => handleExportToCsv()}
            >Export CSV</GlobalSecondaryButton>}
        </Box>
      </Grid>
      <br />
      <GridRender
        gridColumns={GridColumns}
        reloadList={getRequest}
        requests={filteredRequests}
        showCompletedRequests={showCompletedRequests}
        showCancelledRequests={showCancelledRequests}
        loading={loading}
        currentUser={currentUser}
      />
      <FilterMenu
        open={filteringOpen}
        setOpen={setFilteringOpen}
        applyBtnAction={applyFilters}
        cancelButtonAction={closeFiltering}
        filteringInfo={filterOptions}
        appliedFilters={filters}
        setAppliedFilters={setFilters}
      />
    </>
  );

}

export default GridRequests;