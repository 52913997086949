import Checkbox from "@mui/material/Checkbox";
import Divider from "@mui/material/Divider";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import FormLabel from "@mui/material/FormLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Switch from "@mui/material/Switch";
import { styled } from "@mui/material/styles";
import { GlobalSecondaryButton } from "../../../styles";
import { defaultShippingAddress, labelProp } from "../constants";
import { DatePickerKeyDownEvent, somethingIsDifferent } from "../../../../global";
import { WorkRequestFormAutocomplete, WorkRequestFormDate, WorkRequestFormInput } from "../WorkRequestFormInput";
import { useEffect } from "react";
import WorkRequestNonChemicalTable from "../../NewWorkRequests/components/WorkRequestNonChemicalTable";
import SampleSafetyInfo from "./SampleSafetyInfo";

const StyledDivider = styled(Divider)({
  marginTop: "10px",
});

const StyledDiv = styled("div")({
  display: "flex",
  marginTop: "5px",
});

export default function ShippingInformation({
  setAddressBookOpen,
  handleAddressBook,
  setShippingAddress,
  shippingAddress,
  setShippingRequest,
  shippingRequest,
  shippingCarriersLoading,
  shippingCarriers,
  shippingReasonsLoading,
  shippingReasons,
  errors,
  setErrors,
  addCommentComponent,
  nonChemicalShipping,
  setNonChemicalShipping,
  myWorkRequest,
  unitOfMeasureWUnit,
  unitOfMeasureLoading,
  mySamplesInfo,
  dotHazardClass,
  dotHazardClassLoading,
}) {
  function handlePrint() {
    window.print();
  }

  function clearAddress() {
    setShippingAddress(defaultShippingAddress)
  }

  useEffect(() => {
    const shippingAddressChanged = somethingIsDifferent(shippingAddress, defaultShippingAddress);
    if (!shippingAddressChanged) {
      let method = null

      mySamplesInfo.find(f => f.containers.find(c => {
        if (c.testSubmissionJSON) {
          const parsedValue = JSON.parse(c.testSubmissionJSON)
          method = parsedValue?.methods && parsedValue?.methods.length > 0 ? parsedValue.methods.find(m => m.externalLaboratory?.shippingAddress) : null;
          return true;
        }
        return false;
      }))

      if (method) {
        handleAddressBook(method.externalLaboratory?.shippingAddress);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const commonProps = {
    setData: (data) => {
      setShippingRequest(data);
    },
    data: shippingRequest,
    setErrors,
    errors
  };

  const commonPropsAddress = {
    setData: (data) => {
      setShippingAddress(data);
    },
    data: shippingAddress,
    setErrors,
    errors
  };

  return (
    <div className="work-request-shipping-information">
      {myWorkRequest.isNonChemical && (
        <WorkRequestNonChemicalTable
          myWorkRequest={myWorkRequest}
          unitOfMeasureLoading={unitOfMeasureLoading}
          unitOfMeasure={unitOfMeasureWUnit}
          nonChemicalShipping={nonChemicalShipping}
          setNonChemicalShipping={setNonChemicalShipping}
        />
      )}
      <div className="header-button">
        <div>
          <GlobalSecondaryButton
            className="button"
            onClick={handlePrint}
          >
            Print
          </GlobalSecondaryButton>
          <GlobalSecondaryButton
            className="button"
            onClick={() => setAddressBookOpen(true)}
          >
            Add from Address Book
          </GlobalSecondaryButton>
          <GlobalSecondaryButton
            className="button"
            onClick={clearAddress}
          >
            Clear Address
          </GlobalSecondaryButton>
        </div>
      </div>

      <div className="first-grid">
        <WorkRequestFormInput
          {...commonPropsAddress}
          label={labelProp['recipientName']}
          property="recipientName"
          required={true}
        />

        <WorkRequestFormInput
          {...commonPropsAddress}
          label="Recipient's Email"
          property="emailAddress"
          maxLength={60}
        />

        <WorkRequestFormInput
          {...commonPropsAddress}
          label={labelProp['phoneNumber1']}
          property="phoneNumber1"
          required={true}
          maxLength={30}
        />

        <WorkRequestFormInput
          {...commonPropsAddress}
          label={labelProp['country']}
          property="country"
          required={true}
          maxLength={50}
        />
        <WorkRequestFormInput
          {...commonPropsAddress}
          label={labelProp['postalCode']}
          property="postalCode"
          required={true}
          maxLength={20}
        />

        <WorkRequestFormInput
          {...commonPropsAddress}
          label={labelProp['state']}
          property="state"
          required={true}
          maxLength={20}
        />
        <WorkRequestFormInput
          {...commonPropsAddress}
          label={labelProp['city']}
          property="city"
          required={true}
          maxLength={60}
        />

      </div>

      <div className="second-grid">
        <WorkRequestFormInput
          {...commonPropsAddress}
          label={labelProp['address1']}
          property="address1"
          required={true}
          maxLength={255}
        />
        <WorkRequestFormInput
          {...commonPropsAddress}
          label="Company"
          property="company"
          maxLength={255}
        />
        <WorkRequestFormInput
          {...commonPropsAddress}
          label="Department/Floor No."
          property="department"
          maxLength={255}
        />

        <WorkRequestFormDate
          {...commonProps}
          property={'requestedDeliveryDate'}
          label={labelProp['requestedDeliveryDate']}
          minDate={new Date()}
          required
          onKeyDown={DatePickerKeyDownEvent}
        />
        <WorkRequestFormAutocomplete
          {...commonProps}
          options={shippingCarriers}
          noOptionsText={
            shippingCarriersLoading
              ? "Loading Carriers..."
              : "No Carriers Found"
          }
          property={'shippingCarrierID'}
          propertyLabel={'carrierName'}
          label={labelProp['shippingCarrierID']}
          required
        />

        <WorkRequestFormAutocomplete
          {...commonProps}
          options={shippingReasons}
          noOptionsText={
            shippingReasonsLoading ? "Loading Reasons..." : "No Reasons Found"
          }
          property={'shippingReasonName'}
          label={labelProp['shippingReasonName']}
          required
        />
      </div>

      <div style={{ display: "flex" }}>
        <FormControl
          error={errors.isPrepaid}
        >
          <FormLabel id="shipment-payment-radio-buttons-group">
            * Shipment Payment:
          </FormLabel>
          <RadioGroup
            row
            aria-labelledby="shipment-payment-radio-buttons-group"
            name="shipment-payment-radio-buttons-group"
            onChange={(e) => {
              const value = e.target.value;
              commonProps.setData({
                ...commonProps.data,
                isPrepaid: value === "true" ? true : false,
              })
              setErrors({
                ...errors,
                isPrepaid: false
              });
            }}
            value={commonProps.data.isPrepaid}
          >
            <FormControlLabel
              value={true}
              control={<Radio />}
              label="Prepaid"
            />
            <FormControlLabel
              value={false}
              control={<Radio />}
              label="Collect"
            />
          </RadioGroup>
        </FormControl>
      </div>

      <div>
        <StyledDivider />
        <StyledDiv>
          <p
            className="shipping-label"
          >
            ETQ Info
          </p>
        </StyledDiv>

        <FormGroup>
          <FormControlLabel
            control={<Switch />}
            onChange={(e) => {
              commonProps.setData({
                ...commonProps.data,
                is3PTesting: e.target.checked,
              })
            }}
            checked={
              commonProps.data?.is3PTesting === null
                ? false
                : commonProps.data?.is3PTesting
            }
            label="Request is for 3rd party testing?"
          />
        </FormGroup>
      </div>
      <div>
        <StyledDivider />
        <StyledDiv>
          <p
            style={{
              fontFamily: "EMprint",
              fontWeight: "600",
              fontSize: "16px",
              color: "#545459",
              textTransform: "none",
              textAlign: "left",
              paddingTop: "20px",
            }}
          >
            Sample Safety Info
          </p>
        </StyledDiv>
        <SampleSafetyInfo
          mySamplesInfo={mySamplesInfo}
          myWorkRequest={myWorkRequest}
          setShippingRequest={setShippingRequest}
          shippingRequest={shippingRequest}
          dotHazardClass={dotHazardClass}
          dotHazardClassLoading={dotHazardClassLoading}
        />
      </div>
      <StyledDivider />
      <div>
        <p className="shipping-label">
          TSCA Review
        </p>
        <FormGroup>
          <FormControlLabel
            control={<Checkbox />}
            checked={commonProps.data.isTSCAReview}
            onChange={(e) => {
              commonProps.setData({
                ...commonProps.data,
                isTSCAReview: e.target.checked,
              })
            }}
            label={<>TSCA Review is Required</>}
          />
        </FormGroup>
      </div>

      <StyledDivider />
      <StyledDiv>
        <p className="shipping-label">
          Terms
        </p>
      </StyledDiv>

      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              checked={commonProps.data.acknowledge}
              onChange={(e) => {
                commonProps.setData({
                  ...commonProps.data,
                  acknowledge: e.target.checked,
                })
              }}
            />
          }
          label="I acknowledge that the descriptions on this form will be printed on MIDAS labels and are deemed acceptable for external use"
        />

        <FormControlLabel
          control={
            <Checkbox
              checked={commonProps.data.containsSamples}
              onChange={(e) => {
                commonProps.setData({
                  ...commonProps.data,
                  containsSamples: e.target.checked,
                })
              }}
            />
          }
          label="This shipment contains chemicals"
        />
      </FormGroup>
      {addCommentComponent()}
    </div>
  );
}
