import { useContext, useEffect, useState } from "react";
import { Button, Divider, Drawer, Toolbar, Box, CircularProgress } from "@mui/material";
import { GlobalButton, GlobalSecondaryButton } from "../../styles";
import WorkRequestInformation from "./WorkRequestInformation";
import "./styles.css";
import WorkRequestBusinessUnit from "../../../api/WorkRequest/WorkRequestBusinessUnit";
import WorkRequestPriority from "../../../api/WorkRequest/WorkRequestPriority";
import WorkRequestFacility from "../../../api/WorkRequest/WorkRequestFacility";
import { CONTAINERING_METHOD_ENUM, RECOMMENDATIONS_ERROR_MESSAGES, StyledTextField, SubTitle, defaultErrorsInformation, defaultErrorsShippingInformation, defaultShippingAddress, defaultShippingItem, defaultShippingRequest, defaultWorkRequest, labelProp } from "./constants";
import ModalSimpleButton from "../../../components/Modal/ModalSimpleButton";
import ShippingInformation from "./ShippingInformation";
import ShippingReason from "../../../api/WorkRequest/ShippingReason";
import ShippingCarrier from "../../../api/WorkRequest/ShippingCarrier";
import AddressBook from "../AddressBook";
import ShippingPrint from "../NewWorkRequests/ShippingPrint";
import ContainersAndTests from "./ContainersAndTests/";
import UnitOfMeasure from "../../../api/Admin/UnitOfMeasure";
import UserContext from "../../../context/UserContext";
import DOTHazardClass from "../../../api/WorkRequest/DOTHazardClass";
import { BuildTestingPayload, ConvertUOMs, GetRequiredSampleSize, GetRequiredSampleSizeUoM, Roles, convertCelsiusToFahrenheit, convertFahrenheitToCelsius, convertFlashPoint, formatMidasNumber, hasRole, somethingIsDifferent } from "../../../global";
import WorkRequestApi from "../../../api/WorkRequest/WorkRequest";
import TSCAReviewApi from "../../../api/WorkRequest/TSCAReview";
import { useHistory } from "react-router-dom";

enum DisplayScreenEnum {
  WorkRequestInformation,
  AddContainersAndTests,
  AddShippingInformation,
}

const drawerWidth = 250

const NewWorkRequestRedesign = ({ match }: any) => {
  const workRequestId = match?.params?.id;

  useEffect(() => {
    if (workRequestId) {
      WorkRequestApi.getById(workRequestId).then((result: any) => {
        if (result) {
          setMyWorkRequest(result);
          checkErrors(result);
          if (result.workRequestSamples && result.workRequestSamples.length > 0) {
            const workRequestSamples: any[] = [];

            result.workRequestSamples.forEach((sample: any) => {
              const sampleFound = workRequestSamples.find(f => f.sample.sampleName === sample.sample.sampleName);
              if (sampleFound) {
                sampleFound.containers.push(sample)
              } else {
                workRequestSamples.push({
                  sample: sample.sample,
                  containers: [{
                    ...sample
                  }]
                })
              }
            })

            setMySamplesInfo(workRequestSamples);
          }
          if (result.shippingRequest) {
            const items = result.shippingRequest.shippingItems;
            const nonChemicalItems = items.filter((f: any) => !f.sampleName && !f.containerID);
            setNonChemicalShipping(nonChemicalItems.length > 0 ? nonChemicalItems : [defaultShippingItem])
            result.shippingRequest.shippingItems = items.filter((f: any) => f.sampleName || f.containerID).map((item: any) => {
              const sampleInfo = result.workRequestSamples.find((s: any) => s.existingContainerID === item.containerID);
              const flashPointC = item.flashPointInC ? convertFlashPoint(convertCelsiusToFahrenheit, item.flashPointInC) ?? null : null;
              return {
                ...item,
                sampleName: sampleInfo?.sample?.sampleName,
                sample: sampleInfo?.sample,
                description: sampleInfo?.sample?.description,
                flashPointInC: flashPointC,
                dotHazardClass: item.dotHazardClass ? parseInt(item.dotHazardClass) : item.dotHazardClass
              }
            })
            setShippingRequest(result.shippingRequest);

            if (result.shippingRequest.shippingAddress) {
              setShippingAddress(result.shippingRequest.shippingAddress);
            };

            checkShippingErrors(result.shippingRequest.shippingAddress, result.shippingRequest);
          }
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workRequestId]);

  const [displayScreen, setDisplayScreen] = useState<DisplayScreenEnum>(DisplayScreenEnum.WorkRequestInformation);
  const [requestFacilities, setRequestFacilities] = useState([]);
  const [requestFacilitiesLoading, setRequestFacilitiesLoading] = useState(true);
  const [businessUnits, setBusinessUnits] = useState([]);
  const [businessUnitsLoading, setBusinessUnitsLoading] = useState(true);
  const [workRequestPriority, setWorkRequestPriority] = useState("");
  const [workRequestPriorityLoading, setWorkRequestPriorityLoading] = useState(true);
  const [errors, setErrors] = useState<Record<string, boolean | null | undefined>>(defaultErrorsInformation);
  const [errorsShipping, setErrorsShipping] = useState<Record<string, boolean | null | undefined>>(defaultErrorsShippingInformation);
  const [myWorkRequest, setMyWorkRequest] = useState({ ...defaultWorkRequest });
  const [modalMessagesOpen, setModalMessagesOpen] = useState(false);
  const [modalMessagesText, setModalMessagesText] = useState("");
  const [modalMessagesTitle, setModalMessagesTitle] = useState("");
  const [modalMessagesButtonText, setModalMessagesButtonText] = useState("Ok");
  const [shippingReasons, setShippingReasons] = useState([]);
  const [shippingReasonsLoading, setShippingReasonsLoading] = useState(true);
  const [shippingCarriers, setShippingCarriers] = useState([]);
  const [shippingCarriersLoading, setShippingCarriersLoading] = useState(true);
  const [addressBookOpen, setAddressBookOpen] = useState(false);
  const [shippingAddress, setShippingAddress] = useState(defaultShippingAddress);
  const [shippingRequest, setShippingRequest] = useState(defaultShippingRequest);
  const [unitOfMeasure, setUnitOfMeasure] = useState([]);
  const [unitOfMeasureWUnit, setUnitOfMeasureWUnit] = useState([]);
  const [unitOfMeasureLoading, setUnitOfMeasureLoading] = useState(true);
  const [mySamplesInfo, setMySamplesInfo] = useState<any[]>([]);
  const [newComment, setNewComment] = useState("");
  const [nonChemicalShipping, setNonChemicalShipping] = useState<any[]>([]);
  const [dotHazardClass, setDOTHazardClass] = useState([]);
  const [dotHazardClassLoading, setDOTHazardClassLoading] = useState(true);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const currentUser = useContext(UserContext);
  const userRoles = currentUser?.idTokenClaims as any;
  const isDeveloperRole = hasRole(Roles.Developer, userRoles?.roles);

  const history = useHistory();

  const usingShipping = somethingIsDifferent(shippingRequest, defaultShippingRequest) || somethingIsDifferent(shippingAddress, defaultShippingAddress) || nonChemicalShipping.some(f => !!f.description) ;
 
  const mountComments = (comments: string[]) => {
    let comment = "";
    comments.forEach((item: string) => {
      comment += item + "\n";
    });
    return comment;
  };

  const hasError = () => Object.entries(errors).some(([key, value]) => value === null || value === true);
  const hasErrorShipping = () => (usingShipping && Object.entries(errorsShipping).some(([key, value]) => value === null || value === true));

  function updateErrors() {
    setErrors((prevErrors: any) => {
      const updatedErrors = Object.keys(prevErrors).reduce((acc: any, key) => {
        acc[key] = prevErrors[key] === null ? true : prevErrors[key];
        return acc;
      }, {});

      return updatedErrors;
    });
  }

  function updateErrorsShipping() {
    setErrorsShipping((prevErrors: any) => {
      const updatedErrors = Object.keys(prevErrors).reduce((acc: any, key) => {
        acc[key] = !usingShipping ? false : prevErrors[key] === null ? true : prevErrors[key];
        return acc;
      }, {});

      return updatedErrors;
    });
  }

  const errorsToString = Object.entries(errors).filter(([key, value]) => value === null || value === true).map(([key]) => labelProp[key]).join('\n')
  const errorsToStringShipping = Object.entries(errorsShipping).filter(([key, value]) => value === null || value === true).map(([key]) => labelProp[key]).join('\n')

  useEffect(() => {
    let cancelPromise = false;

    Promise.all([
      WorkRequestFacility.getAllActive().then((res) => {
        if (cancelPromise) return;
        setRequestFacilitiesLoading(false);
        setRequestFacilities(
          res.sort((a: any, b: any) => a.facilityName.localeCompare(b.facilityName))
        );
      }),
      WorkRequestPriority.getAll()
        .then((res) => {
          setWorkRequestPriority(res.map((priority: any) => ({
            ...priority,
            priority: priority.priorityName
          })));
          setWorkRequestPriorityLoading(false);
        })
        .catch(() => {
          setWorkRequestPriorityLoading(false);
        }),
      WorkRequestBusinessUnit.getAllActive().then((res) => {
        if (cancelPromise) return;
        setBusinessUnitsLoading(false);
        setBusinessUnits(
          res.sort((a: any, b: any) =>
            a.businessUnitName.localeCompare(b.businessUnitName)
          )
        );
      }),
      ShippingReason.getAllActive().then((res) => {
        if (cancelPromise) return;
        setShippingReasonsLoading(false);
        setShippingReasons(
          res.sort((a: any, b: any) =>
            a.shippingReasonName.localeCompare(b.shippingReasonName)
          )
        );
      }),

      ShippingCarrier.getAllActive().then((res) => {
        if (cancelPromise) return;
        setShippingCarriersLoading(false);
        setShippingCarriers(
          res.map((carrier: any) => ({
            ...carrier,
            shippingCarrierID: carrier.id,
          })).sort((a: any, b: any) => a.carrierName.localeCompare(b.carrierName))
        );
      }),
      UnitOfMeasure.getAllActive().then((res) => {
        if (cancelPromise) return;
        setUnitOfMeasureLoading(false);
        setUnitOfMeasureWUnit(
          res
            .filter(
              (result: any) =>
                result.type === "weight" ||
                result.type === "volume" ||
                result.type === "unit"
            )
            .sort((a: any, b: any) => a.uoMName.localeCompare(b.uoMName))
        );
        setUnitOfMeasure(
          res
            .filter(
              (result: any) =>
                (result.type === "weight" || result.type === "volume") &&
                result.metricStandardConversion !== null
            )
            .sort((a: any, b: any) => a.uoMName.localeCompare(b.uoMName))
        );
      }),
      DOTHazardClass.getAllActive().then((res: any) => {
        if (cancelPromise) return;
        setDOTHazardClassLoading(false);
        setDOTHazardClass(
          res.sort((a: any, b: any) => a.displayName.localeCompare(b.displayName))
        );
      })
    ]).then(() => {
    });

    return () => {
      cancelPromise = true;
    };
  }, []);

  function openModalMessages(title: string, text: string) {
    setModalMessagesOpen(true);
    setModalMessagesTitle(title);
    setModalMessagesText(text);
    setModalMessagesButtonText("Ok");
  }

  function closeModalMessages() {
    setModalMessagesOpen(false);
    setModalMessagesText("");
    setModalMessagesButtonText("");
    setModalMessagesTitle("");

    if (redirect) {
      history.push("/workRequests");
    }
  }

  const mySamplesRequired = mySamplesInfo.length < 1 && !myWorkRequest.isNonChemical;

  async function handleChangeScreen(newDisplayScreen: DisplayScreenEnum) {
    if (newDisplayScreen === displayScreen) return;

    if (hasError() && DisplayScreenEnum.WorkRequestInformation === displayScreen) {
      updateErrors();

      openModalMessages(
        "Missing information!",
        "There are required fields missing:\n\n" + errorsToString
      );
      return;
    } else if (DisplayScreenEnum.AddContainersAndTests === displayScreen && mySamplesRequired && newDisplayScreen > DisplayScreenEnum.AddContainersAndTests) {
      openModalMessages(
        "Missing information!",
        "At least one sample info is mandatory!"
      );
    } else if (hasErrorShipping() && DisplayScreenEnum.AddShippingInformation === displayScreen && newDisplayScreen > DisplayScreenEnum.AddShippingInformation) {
      updateErrorsShipping();
      openModalMessages(
        "Missing information!",
        "There are required fields missing Shipping:\n\n" + errorsToStringShipping
      );
    } else {
      setDisplayScreen(newDisplayScreen);
    }
  }

  // eslint-disable-next-line
  async function payloadToGetRecommendation(methods: any, workRequestSamples: any) {
    const errosArray = checkAmountAvailable(
      methods,
      workRequestSamples
    );
    if (errosArray.length > 0) {
      openModalMessages(
        "Container Amount Error",
        `${RECOMMENDATIONS_ERROR_MESSAGES.INVALID_AMOUNT}\n${errosArray.join('\n')}`
      );
      return;
    }

    let newTests = []
    if (methods.length > 0) {
      for (const oMethodInfo of methods) {
        const tests = await BuildTestingPayload(oMethodInfo, null, null, myWorkRequest.chargeCode, currentUser.username)

        if (tests === null || typeof tests === 'string' || typeof tests[0] === 'string') {
          if (Array.isArray(tests)) {
            openModalMessages("Error Building Tests", "")
            return
          } else {
            openModalMessages("Error Building Tests", `There was an error building the test payload. ${tests ? tests : ''}`)
            return
          }
        }
        for (const oTest of tests) {
          newTests.push(oTest)
        }
      }
    }
    return {
      containeringMethod: CONTAINERING_METHOD_ENUM.MIDAS,
      requireParentContainer: false,
      tests: newTests,
    };
  }

  function checkAmountAvailable(methods: any, workRequestSamples: any) {
    let containerAmtError: string[] = [];

    workRequestSamples.forEach((sample: any) => {
      const currentContainer = sample.sample.containers.find(
        (x: any) => x.id === sample.existingContainerID
      );
      const amtNeededForTest = methods.reduce((acc: any, currentMethod: any) => {
        let convertedValue = ConvertUOMs(
          sample.sample,
          sample.sample.substance,
          GetRequiredSampleSize(currentMethod),
          unitOfMeasure.find(
            (x: any) =>
              x.uoMName === GetRequiredSampleSizeUoM(currentMethod)
          ),
          currentContainer?.uom ?? null
        );
        return acc + convertedValue;
      }, 0);

      if (currentContainer?.currentAmount < amtNeededForTest) {
        containerAmtError.push(
          `Container ${currentContainer.containerNumber
          } of Sample ${formatMidasNumber(
            sample.sample.sampleName
          )} does not have enough volume for all tests. Amount needed: ${amtNeededForTest.toFixed(
            2
          )} ${currentContainer.containerSizeUoM
          } - Container amount: ${currentContainer.currentAmount.toFixed(2)} ${currentContainer.containerSizeUoM
          }`
        );
      }
    });
    return containerAmtError;
  }

  const prepareData = async (workRequest: any, draft: boolean) => {
    const workRequestSamples = mySamplesInfo.flatMap((samples: any) => (samples.containers));
    // let testingContainers = null;

    // if (workRequestSamples.length > 0) {
    //   const methods = workRequestSamples.filter(f => f.testSubmissionJSON && f.testSubmissionJSON !== '').flatMap((samples: any) => (JSON.parse(samples.testSubmissionJSON))?.methods);
    //   testingContainers = payloadToGetRecommendation(methods, workRequestSamples);
    //   if (!testingContainers && methods.length > 0) return;
    // }

    return {
      ...workRequest,
      statusName: draft ? 'Draft' : 'Submitted',
      typeName: usingShipping ? 'Shipping' : workRequestSamples.some(s => s.testSubmissionJSON && s.testSubmissionJSON !== '') ? 'Container Registration w/ Tests' : 'Container Registration',
      workRequestSamples: workRequestSamples.map((sample) => {
        let currentContainer = sample.sample.containers.find(
          (x: any) => x.id === sample.existingContainerID
        );
        delete sample.newContainer;
        return {
          ...sample,
          requestedAmount:
            sample.requestedAmount ?? currentContainer.currentAmount,
          requestedAmountUoM:
            sample.requestedAmountUoM ?? currentContainer.containerSizeUoM,
          sample: null,
          sampleName: sample.sample?.sampleName,
          unitOfMeasure: null,
          location: null,
          container: null,
        };
      }),
      shippingRequest: usingShipping ? prepareDataShipping() : null
    };
  };

  const prepareDataShipping = () => {
    let chemShipping = structuredClone(shippingRequest).shippingItems;

    chemShipping.forEach(
      (x: any, index, array: any) => {
        const flashPoint = x.flashPointInC ? convertFlashPoint(convertFahrenheitToCelsius, x.flashPointInC) ?? null : null
        array[index].flashPointInC = flashPoint;
      });      

    return {
      ...shippingRequest,
      shippingAddress: shippingAddress,
      printBlindCode: shippingRequest.shippingItems.some((s: any) => s.blindCodeDescription && s.blindCodeDescription !== ''),
      numberOfItems: shippingRequest.shippingItems.length,
      shippingItems: [
        ...chemShipping,
        ...nonChemicalShipping.filter((x) => !!x.description),
      ],
    };
  };

  async function createTscaRequest(workRequest: any) {
    if (workRequest.shippingRequest !== null && workRequest.shippingRequest.isTSCAReview && workRequest.statusName !== 'Draft') {
      let response = await TSCAReviewApi.createTSCAReview({
        shippingRequestID: workRequest?.shippingRequest?.id,
        tscaStatusName: "Submitted",
      })
        .then((res) => true)
        .catch((err) => false);

      return response;
    }

    return true;
  }

  async function submit(draft: boolean) {
    if (hasError() && !draft) {
      updateErrors();

      openModalMessages(
        "Missing information!",
        "There are required fields missing:\n\n" + errorsToString
      );
      return;
    } else if (mySamplesRequired && !draft) {
      openModalMessages(
        "Missing information!",
        "At least one sample info is mandatory!"
      );
    } else if (hasErrorShipping() && !draft) {
      updateErrorsShipping();

      openModalMessages(
        "Missing information!",
        "There are required fields missing in Shipping:\n\n" + errorsToStringShipping
      );
    } else {
      console.log('Work Request Information:');
      console.log(myWorkRequest);
      console.log('Containers:');
      console.log(mySamplesInfo);
      console.log('Shipping:');
      console.log({
        ...shippingRequest,
        shippingAddress: shippingAddress,
      });
      setLoadingSubmit(true);
      const data = await prepareData(myWorkRequest, draft);

      const api = data.id && data.id > 0 ? WorkRequestApi.newUpdateWorkRequest : WorkRequestApi.newCreateWorkRequest;

      api(data)
        .then(async (res) => {
          if (res?.message !== 'Success') {
            openModalMessages('Fail', `Something went wrong saving the Work Requests, please try again later. ${res?.message ? res?.message : ""}`);
            setLoadingSubmit(false);
          } else {
            const workRequest = res.result;
            await createTscaRequest(workRequest);

            openModalMessages(
              "Work Request Save",
              "Work Request Saved Successfully"
            );

            setRedirect(true);
            setLoadingSubmit(false);
          }
        })
        .catch((err) => {
          setLoadingSubmit(false);
          openModalMessages('Fail', `Something went wrong saving the Work Requests, please try again later. ${err?.message ? err?.message : ""}`);
        });
    }
  }

  function handleAddressBook(addressBook: any) {
    if (addressBook) {
      setShippingAddress(() => ({
        ...shippingAddress,
        city: addressBook.city,
        state: addressBook.state,
        postalCode: addressBook.postalCode,
        country: addressBook.country,
        phoneNumber1: addressBook.phoneNumber1,
        emailAddress: addressBook.emailAddress,
        recipientName: addressBook.recipientName,
        address1: addressBook.address1,
        company: addressBook.company,
        department: addressBook.department,
      }));

      if (usingShipping) {
        checkShippingErrors(addressBook, shippingRequest);
      }
    }
  }

  function checkErrors(workRequestObject: any) {
    const error: any = defaultErrorsInformation;
    Object.keys(defaultErrorsInformation).forEach((key: string) => {
      if (workRequestObject.hasOwnProperty(key)) {
        const object = workRequestObject as any;
        error[key] = !object[key] || object[key] === '' ? true : false;
      }
    })
    setErrors((prevErrors) => ({
      ...prevErrors,
      ...error,
    }))
}

  function checkShippingErrors(addressObject: any, shippingObject: any) {
      const shippingError: any = defaultErrorsShippingInformation;
      Object.keys(defaultErrorsShippingInformation).forEach((key: string) => {
        if (addressObject.hasOwnProperty(key)) {
          const object = addressObject as any;
          shippingError[key] = !object[key] || object[key] === '' ? true : false;
        } else if (shippingObject.hasOwnProperty(key)) {
          const request = shippingObject as any;
          shippingError[key] = request[key] === undefined || request[key] === null || request[key] === '' ? true : false;
        }
      })
      setErrorsShipping((prevErrors) => ({
        ...prevErrors,
        ...shippingError,
      }))
  }

  const renderCorrectPage = () => {
    switch (displayScreen) {
      case DisplayScreenEnum.WorkRequestInformation: {

        return <WorkRequestInformation
          requestFacilities={requestFacilities}
          requestFacilitiesLoading={requestFacilitiesLoading}
          businessUnits={businessUnits}
          businessUnitsLoading={businessUnitsLoading}
          workRequestPriority={workRequestPriority}
          workRequestPriorityLoading={workRequestPriorityLoading}
          errors={errors}
          setErrors={setErrors}
          myWorkRequest={myWorkRequest}
          setMyWorkRequest={setMyWorkRequest}
          addCommentComponent={addCommentComponent}
          setNonChemicalShipping={setNonChemicalShipping}
        />
      }
      case DisplayScreenEnum.AddContainersAndTests: {

        return <ContainersAndTests
          isReadOnly={false}
          mySamplesInfo={mySamplesInfo}
          setMySamplesInfo={setMySamplesInfo}
          unitOfMeasureLoading={unitOfMeasureLoading}
          unitOfMeasure={unitOfMeasure}
          addCommentComponent={addCommentComponent}
          shippingRequest={shippingRequest}
          setShippingRequest={setShippingRequest}
        />
      }
      case DisplayScreenEnum.AddShippingInformation: {

        return <ShippingInformation
          shippingAddress={shippingAddress}
          setShippingAddress={setShippingAddress}
          shippingRequest={shippingRequest}
          setShippingRequest={setShippingRequest}
          setAddressBookOpen={setAddressBookOpen}
          handleAddressBook={handleAddressBook}
          errors={errorsShipping}
          setErrors={setErrorsShipping}
          shippingCarriersLoading={shippingCarriersLoading}
          shippingCarriers={shippingCarriers}
          shippingReasonsLoading={shippingReasonsLoading}
          shippingReasons={shippingReasons}
          addCommentComponent={addCommentComponent}
          nonChemicalShipping={nonChemicalShipping}
          setNonChemicalShipping={setNonChemicalShipping}
          myWorkRequest={myWorkRequest}
          unitOfMeasureWUnit={unitOfMeasureWUnit}
          unitOfMeasureLoading={unitOfMeasureLoading}
          mySamplesInfo={mySamplesInfo}
          dotHazardClass={dotHazardClass}
          dotHazardClassLoading={dotHazardClassLoading}
        />
      }

      default: {
        alert(displayScreen);
      }
    }
  };

  const addCommentComponent = () => {
    return <>
      <SubTitle>Add Comment</SubTitle>
      <div style={{ display: "flex", gap: "15px", alignItems: "baseline" }}>
        <StyledTextField
          size="small"
          margin="normal"
          variant="outlined"
          label="Comment"
          InputLabelProps={{ shrink: true }}
          value={newComment}
          onChange={(e) => setNewComment(e.target.value)}
        />

        <GlobalButton
          style={{ width: 160 }}
          name="saveComment"
          variant="contained"
          onClick={() => {
            let timeStamp = new Date().toLocaleString();

            let commentWithDate = `${currentUser.username} (${timeStamp}):  ${newComment}`;
            let comment = myWorkRequest.comments
              ? commentWithDate + "|" + myWorkRequest.comments
              : commentWithDate;
            setMyWorkRequest({
              ...myWorkRequest,
              comments: comment,
            });
            setNewComment("");
          }}
          disabled={newComment === "" || !newComment}
        >
          Save Comment
        </GlobalButton>
      </div>
      <div style={{ display: "flex" }}>
        <StyledTextField
          size="small"
          margin="normal"
          variant="outlined"
          label="Comments History"
          InputLabelProps={{ shrink: true }}
          inputProps={{ maxLength: 500 }}
          multiline
          rows={4}
          value={
            myWorkRequest.comments
              ? mountComments(myWorkRequest.comments.split("|"))
              : ""
          }
          disabled={true}
        />
      </div>
    </>
  }

  if (!isDeveloperRole) return;

  return (
    <div className="new-work-request-redesign">
      <span className='pageheader'>Create Work Request</span>
      <Divider className='dividerbar' />
      <Drawer sx={{
        width: 200,
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
        marginTop: "25px",
      }}
        variant="permanent"
        open
        anchor="right"
      >
        <Box style={{ height: "69px" }}></Box>
        <Toolbar />
        <Divider />

        <p className="summary-title">Work Request Summary</p>

        <Button className={`summary-button${displayScreen >= DisplayScreenEnum.WorkRequestInformation ? ' active' : ''}`}
          onClick={() => handleChangeScreen(DisplayScreenEnum.WorkRequestInformation)}
        >Work Request Information</Button>
        <p></p>

        <Button className={`summary-button${displayScreen >= DisplayScreenEnum.AddContainersAndTests ? ' active' : ''}`}
          onClick={() => handleChangeScreen(DisplayScreenEnum.AddContainersAndTests)}
        >Add Containers and Tests</Button>

        <Button className={`summary-button${displayScreen >= DisplayScreenEnum.AddShippingInformation ? ' active' : ''}`}
          onClick={() => handleChangeScreen(DisplayScreenEnum.AddShippingInformation)}
        >Add Shipping Information</Button>

        <div className="container-buttons">
          {loadingSubmit && <CircularProgress style={{ textTransform: "none", marginTop: "50px", marginLeft: "15px" }}></CircularProgress>}
          <GlobalButton
            className="action-button"
            name="btnSaveWorkRequest"
            variant='contained'
            onClick={() => submit(true)}
            disabled={loadingSubmit}
          >Save All Changes</GlobalButton>
          {displayScreen > DisplayScreenEnum.WorkRequestInformation &&
            <GlobalSecondaryButton
              className="action-button"
              name="btnSubmitWorkRequest"
              variant='contained'
              onClick={() => submit(false)}
              disabled={loadingSubmit}
            >Submit Work Request</GlobalSecondaryButton>}
          {displayScreen === DisplayScreenEnum.WorkRequestInformation && <GlobalSecondaryButton
            name="btnAddContainersAndTests"
            variant='contained'
            className="action-button"
            onClick={() => handleChangeScreen(DisplayScreenEnum.AddContainersAndTests)}
          >Add Containers and Tests</GlobalSecondaryButton>}
          {displayScreen === DisplayScreenEnum.AddContainersAndTests && <GlobalSecondaryButton
            name="btnAddContainersAndTests"
            variant='contained'
            className="action-button"
            onClick={() => handleChangeScreen(DisplayScreenEnum.AddShippingInformation)}
          >Add Shipping Information</GlobalSecondaryButton>}
        </div>

      </Drawer>

      <Box style={{ width: `calc(100vw - ${drawerWidth}px)` }}>
        {renderCorrectPage()}
      </Box>

      <ModalSimpleButton
        title={modalMessagesTitle}
        buttonText={modalMessagesButtonText}
        buttonAction={closeModalMessages}
        open={modalMessagesOpen}
        setOpen={setModalMessagesOpen}
      >
        <label style={{ whiteSpace: "break-spaces" }}>
          {modalMessagesText}
        </label>
      </ModalSimpleButton>

      {addressBookOpen && (
        <AddressBook
          open={addressBookOpen}
          setOpen={setAddressBookOpen}
          handleApply={handleAddressBook}
        />
      )}

      <ShippingPrint
        shippingAddress={shippingAddress}
        shippingRequest={shippingRequest}
        shippingItems={shippingRequest?.shippingItems}
        shippingCarriers={shippingCarriers}
        isTSCA={shippingRequest?.isTSCAReview}
      />
    </div>

  );
};

export default NewWorkRequestRedesign;