import { useState } from "react";
import { Box, IconButton } from '@mui/material'
import { Edit } from "@mui/icons-material";
import { StyledTableBody, StyledTableRow, UXDataTableWithoutBody } from '../../../../components/UXDataTable'
import { StyledTableCellMedium, StyledTableCellSmall } from '../../../Distillations/styles'
import { formatMidasNumber } from "../../../../global";
import UpdateSampleSafetyInfo from "./UpdateSampleSafetyInfo";

const shippingItemsCols = [
    "MIDAS #",
    "Container Number",
    "Current Amount",
    "UoM",
    "PSIMS ID",
    "Blind Code Label Description",
    "Flash Point (F)",
    "DOT Hazard Class",
    "Export Value",
    "Description",
];

const SampleSafetyInfo = ({
    isReadOnly = false,
    shippingRequest,
    setShippingRequest,
    dotHazardClass,
    dotHazardClassLoading,
}) => {
    const [isShippingItemModalOpen, setIsShippingItemModalOpen] = useState(false);
    const [shippingItemToEdit, setShippingItemToEdit] = useState(null);

    const handleShippingItemEditIcon = (shippingItem) => {
        setShippingItemToEdit(shippingItem)
        setIsShippingItemModalOpen(true)
    }

    function onCloseShippingItemModal() {
        setIsShippingItemModalOpen(false);
        setShippingItemToEdit(null)
    }

    return (
        <>
            <Box display="flex" flexDirection="column">
                <UXDataTableWithoutBody
                    tableWidth='100%'
                    cols={shippingItemsCols}
                    blankFirstHeader
                    tableId="shippingItemTable"
                    rowLength={shippingRequest?.shippingItems?.length}
                    noDataFoundMessage={'No container found'}
                    addToolTipText="Add new"
                    addFunction={() => {
                    }}
                >
                    <StyledTableBody>
                        {shippingRequest?.shippingItems && shippingRequest?.shippingItems?.map((shippingItem, index) => {
                            const container = shippingItem.sample?.containers?.find(f => f.id === shippingItem.containerID);
                            const dot = dotHazardClass.find(f=> f.classNumber === shippingItem.dotHazardClass)?.displayName;
                            return <StyledTableRow
                                key={index}
                                hover
                            >
                                <StyledTableCellSmall>
                                    <Box display="flex" alignItems="center" justifyContent="center" gap={1}>
                                        <IconButton
                                            disabled={isReadOnly}
                                            color="info"
                                            style={{ padding: 0 }}
                                        >
                                            <Edit
                                                fontSize="small"
                                                color="inherit"
                                                onClick={() => handleShippingItemEditIcon(shippingItem)}
                                            />
                                        </IconButton>
                                    </Box>
                                </StyledTableCellSmall>
                                <StyledTableCellSmall>
                                    {shippingItem.sample ? formatMidasNumber(shippingItem.sample.sampleName) : "-"}
                                </StyledTableCellSmall>
                                <StyledTableCellSmall>
                                    {container?.containerNumber}
                                </StyledTableCellSmall>
                                <StyledTableCellSmall>
                                    {container?.currentAmount ?? ""}
                                </StyledTableCellSmall>
                                <StyledTableCellSmall>
                                    {shippingItem?.requestedAmountUoM ?? ""}
                                </StyledTableCellSmall>
                                <StyledTableCellSmall>
                                    {shippingItem?.sample
                                        ? shippingItem.sample?.psimsid
                                        : shippingItem.sample?.psimsid ?? ""}
                                </StyledTableCellSmall>
                                <StyledTableCellMedium>
                                    {shippingItem?.blindCodeDescription}
                                </StyledTableCellMedium>
                                <StyledTableCellSmall>
                                    {shippingItem?.flashPointInC}
                                </StyledTableCellSmall>
                                <StyledTableCellMedium>
                                    {dot ?? ''}
                                </StyledTableCellMedium>
                                <StyledTableCellSmall>
                                    {shippingItem?.exportValue}
                                </StyledTableCellSmall>
                                <StyledTableCellMedium>
                                    {shippingItem?.sample?.description}
                                </StyledTableCellMedium>

                            </StyledTableRow>
                        })}
                    </StyledTableBody>
                </UXDataTableWithoutBody>
            </Box>

            {isShippingItemModalOpen && <UpdateSampleSafetyInfo
                isModalOpen={isShippingItemModalOpen}
                setIsModalOpen={setIsShippingItemModalOpen}
                setIsModalClose={onCloseShippingItemModal}
                shippingRequest={shippingRequest}
                setShippingRequest={setShippingRequest}
                shippingItemToEdit={shippingItemToEdit}
                dotHazardClass={dotHazardClass}
                dotHazardClassLoading={dotHazardClassLoading}
            />}
        </>
    )
}

export default SampleSafetyInfo