import { Box } from "@mui/material";
import { useContext, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import SpecialAnalysis from "../../api/SpecialAnalysis/SpecialAnalysis";
import SpecialAnalysisStatus from "../../api/SpecialAnalysis/SpecialAnalysisStatus";
import FilterMenu from "../../components/FilterMenu";
import UserContext from "../../context/UserContext";
import {
  applyFiltersToArray,
  convertDateFormat,
  exportToCsv,
} from "../../global";
import {
  GlobalButton,
  GlobalSecondaryButton,
  GlobalTabCollection,
  StyledTab,
} from "../styles";
import TableAllRequests from "./TableAllRequests";
import TableMyOpenRequests from "./TableMyOpenRequests";

const SpecialAnalyses = ({ copyWorkRequest }) => {
  const [tabValue, setTabValue] = useState(0);
  const [filteringOpen, setFilteringOpen] = useState(false);
  const [filters, setFilters] = useState([
    {
      name: null,
      displayName: null,
      operator: null,
      enumValues: [],
      value: "",
    },
  ]);
  const [allRequestsNoPagination, setAllRequestsNoPagination] = useState([]);

  const [allRequests, setAllRequests] = useState([]);
  const [totalCountAllRequests, setTotalCountAllRequests] = useState(0);
  const [isDataLoading, setIsDataLoading] = useState(true);

  const [myRequests, setMyRequests] = useState([]);
  const [isMyRequestsLoading, setIsMyRequestsLoading] = useState(true);
  const currentUser = useContext(UserContext);
  const userEmail = currentUser.username;

  const [requestStatuses, setRequestStatuses] = useState([]);

  const exportRef = useRef([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const filterOptions = [
    { name: "id", displayName: "Request ID", type: "integer", enumValues: [] },
    {
      name: "description",
      displayName: "Description",
      type: "string",
      enumValues: [],
    },
    {
      name: "assignedToEmail",
      displayName: "Assigned To",
      type: "string",
      enumValues: [],
    },
    {
      name: "requestedByEmail",
      displayName: "Requested By",
      type: "string",
      enumValues: [],
    },
    {
      name: "createdDate",
      displayName: "Requested Date",
      type: "date",
      enumValues: [],
    },
    {
      name: "requestedCompletionDate",
      displayName: "Requested Completion Date",
      type: "date",
      enumValues: [],
    },
    {
      name: "statusName",
      displayName: "Status",
      type: "enum",
      enumValues: requestStatuses,
      multiple: true
    },
    {
      name: "mwrName",
      displayName: "MWR Name",
      type: "string",
      enumValues: [],
    },
  ]; //.sort((a,b) => a.displayName.localeCompare(b.displayName));

  useEffect(() => {
    let cancelPromise = false;

    SpecialAnalysis.getMyOpenSpecialAnalysis(userEmail).then((res) => {
      if (cancelPromise) return;
      res.forEach(el => {
        el.typeName = "Special Analysis"
        el.facilityName = "Special Analysis"
      });

      setMyRequests(res)
      setIsMyRequestsLoading(false);
    });

    return () => {
      cancelPromise = true;
    };
  }, [userEmail]);

  useEffect(() => {
    let cancelPromise = false;
    setPage(0);

    const valueToSearch =
      filters && filters[0] && filters[0].value ? filters[0].value : "";
    const columnName =
      filters && filters[0] && filters[0].name ? filters[0].name : "";
    const criteria =
      filters && filters[0] && filters[0].operator ? filters[0].operator : "";

    SpecialAnalysis.totalCountWithFilter(valueToSearch, columnName, criteria).then(
      (res) => {
        if (!cancelPromise) {
          setTotalCountAllRequests(res);
        }
      }
    );
    SpecialAnalysis.getAllNoPagination(valueToSearch, columnName, criteria).then(
      (res) => {
        if (!cancelPromise) {
          setAllRequestsNoPagination(res);
        }
      }
    );

    return () => {
      cancelPromise = true;
    };
  }, [filters]);

  useEffect(() => {
    let cancelPromise = false;

    const valueToSearch =
      filters && filters[0] && filters[0].value ? filters[0].value : "";
    const columnName =
      filters && filters[0] && filters[0].name ? filters[0].name : "";
    const criteria =
      filters && filters[0] && filters[0].operator ? filters[0].operator : "";
    setIsDataLoading(true);

    SpecialAnalysis.getAllWithFilter(
      page,
      rowsPerPage,
      valueToSearch,
      columnName,
      criteria,
    ).then((res) => {
      if (!cancelPromise) {
        res.forEach(el => {
          el.typeName = "Special Analysis"
          el.facilityName = "Special Analysis"
        });

        setAllRequests(res);
        setIsDataLoading(false);
      }
    });

    return () => {
      cancelPromise = true;
    };
  }, [page, rowsPerPage, filters]);

  useEffect(() => {
    let cancelPromise = false;

    SpecialAnalysisStatus.getAll().then((res) => {
      if (cancelPromise) return;
      setRequestStatuses(
        res
          .filter((result) => result.isActive === true)
          .map((item) => item.statusName)
          .sort()
      );
    });

    return () => {
      cancelPromise = true;
    };
  }, []);

  const handleChange = (event, newValue) => {
    clearFiltersClick();
    setTabValue(newValue);
  };

  function closeFiltering() {
    setFilteringOpen(false);
  }

  function applyFilters() {
    setFilteringOpen(false);
  }

  const filterClick = (event) => {
    setFilteringOpen(true);
  };

  const clearFiltersClick = (event) => {
    setFilters([
      {
        name: null,
        displayName: null,
        operator: null,
        enumValues: [],
        value: null,
      },
    ]);
  };

  const renderSearchTab = () => {
    switch (tabValue) {
      case 0: {
        exportRef.current = myRequests;
        return (
          <TableMyOpenRequests
            filters={filters}
            myRequests={myRequests}
            isDataLoading={isMyRequestsLoading}
            userEmail={userEmail}
            isProcessing={false}
            copyWorkRequest={copyWorkRequest}
            isSpecialAnalysis={true}
          />
        );
      }
      case 1: {
        exportRef.current = allRequestsNoPagination;
        return (
          <TableAllRequests
            filters={filters}
            allRequests={allRequests}
            allRequestsNoPagination={allRequestsNoPagination}
            isDataLoading={isDataLoading}
            isProcessing={false}
            page={page}
            setPage={setPage}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            totalCountAllRequests={totalCountAllRequests}
            userEmail={userEmail}
            copyWorkRequest={copyWorkRequest}
            isSpecialAnalysis={true}
          />
        );
      }
      default: {
        alert(tabValue);
      }
    }
  };

  const renderFilterMenu = () => {
    switch (tabValue) {
      case 0: {
        return (
          <FilterMenu
            open={filteringOpen}
            setOpen={setFilteringOpen}
            applyBtnAction={applyFilters}
            cancelButtonAction={closeFiltering}
            filteringInfo={filterOptions}
            appliedFilters={filters}
            setAppliedFilters={setFilters}
          />
        );
      }
      case 1: {
        return (
          <FilterMenu
            open={filteringOpen}
            setOpen={setFilteringOpen}
            applyBtnAction={applyFilters}
            cancelButtonAction={closeFiltering}
            filteringInfo={filterOptions}
            appliedFilters={filters}
            setAppliedFilters={setFilters}
          />
        );
      }

      case 2: {
        return <></>;
      }
      default: {
        alert(tabValue);
      }
    }
  };

  return (
    <div>
      <Box sx={{ bgcolor: "#fff", pt: 3, pb: 1 }} display="flex">
        <GlobalTabCollection
          style={{ marginRight: "1rem" }}
          scrollButtons="auto"
          variant="scrollable"
          value={tabValue}
          onChange={handleChange}
          aria-label="ant example"
        >
          <StyledTab label="My Open Requests" />
          <StyledTab label="All Requests" />
        </GlobalTabCollection>

        <GlobalButton
          style={{ marginTop: "-.8rem" }}
          variant="contained"
          component={Link}
          to="/newSpecialAnalysis"
        >
          Create New Request
        </GlobalButton>

        <Box
          display="flex"
          alignItems={"center"}
          marginLeft="auto"
          marginTop="-.8rem"
        >
          <GlobalButton
            style={{ marginRight: "1rem" }}
            variant="contained"
            onClick={(e) =>
              exportToCsv(
                applyFiltersToArray(filters, exportRef.current).map((item) => {
                  return {
                    ID: item.id,
                    Category: "Special Analysis",
                    Description: item.description,
                    Requester: item.requestedByEmail,
                    "Assigned To": item.assignedToEmail,
                    "Requested Date": convertDateFormat(item.createdDate),
                    "Requested Completion": convertDateFormat(
                      item.requestedCompletionDate
                    ),
                    "Last Acceptable Date": convertDateFormat(
                      item.lastAcceptableCompletionDate
                    ),
                    Facility: item.facilityName,
                    Status: item.statusName,
                    "MWR Name": item.mwrName,
                  };
                }),
                "Work Requests"
              )
            }
          >
            Export CSV
          </GlobalButton>
          <GlobalButton
            // disabled={tabValue > 1}
            style={{ marginRight: "1rem" }}
            variant="contained"
            onClick={() => filterClick()}
          >
            Filters
          </GlobalButton>

          {!(filters[0].name === null) && (
            <GlobalSecondaryButton
              variant="contained"
              onClick={() => clearFiltersClick()}
            >
              Clear Filters
            </GlobalSecondaryButton>
          )}
        </Box>
        <Box sx={{ p: 1 }} />
      </Box>
      {renderSearchTab()}
      {renderFilterMenu()}
      {/* Informational Messages */}
    </div>
  );
};

export default SpecialAnalyses;
