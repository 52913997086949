import API from "..";

export default class WorkRequest {
  constructor({
    id,
    typeName,
    workRequestType,
    statusName,
    workRequestStatus,
    facilityName,
    workRequestFacility,
    priorityName,
    workRequestPriority,
    description,
    requestedByEmail,
    createdByEmail,
    createdDate,
    lastModifiedByEmail,
    lastModifiedDate,
    requestedCompletionDate,
    lastAcceptableCompletionDate,
    assignedDate,
    inProgressDate,
    completedDate,
    priorityReason,
    comments,
    chargeCode,
    deliveryLocationName,
    workRequestLocation,
    assignedToEmail,
    testSubmissionJSON,
    projectID,
    project,
    workRequestSamples,
    shippingRequest,
    specialRequest,
    businessUnitName,
    mwrName,
    completedByEmail,
    isNonChemical,
  }) {
    this.id = id;
    this.typeName = typeName;
    this.workRequestType = workRequestType;
    this.statusName = statusName;
    this.workRequestStatus = workRequestStatus;
    this.facilityName = facilityName;
    this.workRequestFacility = workRequestFacility;
    this.priorityName = priorityName;
    this.workRequestPriority = workRequestPriority;
    this.description = description;
    this.requestedByEmail = requestedByEmail;
    this.createdByEmail = createdByEmail;
    this.createdDate = createdDate;
    this.lastModifiedByEmail = lastModifiedByEmail;
    this.lastModifiedDate = lastModifiedDate;
    this.requestedCompletionDate = requestedCompletionDate;
    this.lastAcceptableCompletionDate = lastAcceptableCompletionDate;
    this.assignedDate = assignedDate;
    this.inProgressDate = inProgressDate;
    this.completedDate = completedDate;
    this.priorityReason = priorityReason;
    this.comments = comments;
    this.chargeCode = chargeCode;
    this.deliveryLocationName = deliveryLocationName;
    this.workRequestLocation = workRequestLocation;
    this.assignedToEmail = assignedToEmail;
    this.testSubmissionJSON = testSubmissionJSON;
    this.projectID = projectID;
    this.project = project;
    this.workRequestSamples = workRequestSamples;
    this.shippingRequest = shippingRequest;
    this.specialRequest = specialRequest;
    this.businessUnitName = businessUnitName;
    this.mwrName = mwrName;
    this.completedByEmail = completedByEmail;
    this.isNonChemical = isNonChemical;
  }

  static async getAll(page, resultsPerPage) {
    const api = await API();
    const { data } = await api.get(
      `/workRequest/?page=${page}&resultsPerPage=${resultsPerPage}`
    );
    if (data.result) {
      return data.result.map((d) => new WorkRequest(d));
    } else {
      return null;
    }
  }

  static async getAllNoPagination(searchValue, columnName, criteria) {
    const api = await API();
    const { data } = await api.get(`/workRequest/getAllNoPagination`, {
      params: {
        searchValue,
        columnName,
        criteria,
      },
    });
    if (data.result) {
      return data.result.map((d) => new WorkRequest(d));
    } else {
      return null;
    }
  }

  static async getMyWorkRequests(userEmail) {
    const api = await API();
    const { data } = await api.get(
      `/workRequest/myRequests?userEmail=${userEmail}`
    );
    if (data.result) {
      return data.result.map((d) => new WorkRequest(d));
    } else {
      return [];
    }
  }

  static async getMyOpenWorkRequests(userEmail) {
    const api = await API();
    const { data } = await api.get(
      `/workRequest/myOpenRequests?userEmail=${userEmail}`
    );
    if (data.result) {
      return data.result.map((d) => new WorkRequest(d));
    } else {
      return [];
    }
  }

  static async getMyOpenAssignedWorkRequests(userEmail) {
    const api = await API();
    const { data } = await api.get(
      `/workRequest/myOpenAssignedRequests?userEmail=${userEmail}`
    );
    if (data.result) {
      return data.result.map((d) => new WorkRequest(d));
    } else {
      return [];
    }
  }

  static async getQueueRequests() {
    const api = await API();
    const { data } = await api.get(`/workRequest/requestQueue`);
    if (data.result) {
      return data.result.map((d) => new WorkRequest(d));
    } else {
      return [];
    }
  }

  static async createWorkRequest(info) {
    const api = await API();
    const { data } = await api.post("/workRequest", info);
    return data;
  }

  static async updateWorkRequest(info) {
    const api = await API();
    const { data } = await api.patch(`/workRequest`, info);
    return data;
  }

  static async getById(id) {
    const api = await API();
    const { data } = await api.get(`/workRequest/${id}`);
    if (data.result) {
      return new WorkRequest(data.result);
    } else {
      return null;
    }
  }

  static async quickSearch(searchCriteria) {
    const api = await API();
    const { data } = await api.get(
      `workRequest/quickSearch?searchCriteria=${searchCriteria}`
    );
    return data;
  }

  static async updateComment(id, comments) {
    const api = await API();
    const { data } = await api.patch(
      `/workRequest/${id}/comments?comments=${comments}`
    );
    return data;
  }

  static async updateAssignTo(id, email = '') {
    const api = await API();
    const { data } = await api.patch(
      `/workRequest/${id}/assign?userEmail=${email}`
    );
    return data;
  }

  static async cancelRequest(id) {
    const api = await API();
    const { data } = await api.patch(`/workRequest/${id}/cancel`);
    return data;
  }

  static async getAllWithFilter(
    page,
    resultsPerPage,
    searchValue,
    columnName,
    criteria
  ) {
    const api = await API();
    const { data } = await api.get("/workRequest", {
      params: {
        page,
        resultsPerPage,
        searchValue,
        columnName,
        criteria,
      },
    });
    if (data && data.result && data.result.length > 0) {
      return data.result;
    }
    return [];
  }

  static async totalCountWithFilter(searchValue, columnName, criteria) {
    const api = await API();
    const { data } = await api.get("/workRequest/count", {
      params: {
        searchValue,
        columnName,
        criteria,
      },
    });
    if (data && data.result) {
      return data.result;
    }
    return 0;
  }

  static async createContainers(workRequest) {
    const api = await API();
    const { data } = await api.patch("/workRequest/createContainers",workRequest);
    return data;
  }

  static async newCreateWorkRequest(info) {
    const api = await API();
    const { data } = await api.post("/newworkRequest", info);
    return data;
  }

  static async newUpdateWorkRequest(info) {
    const api = await API();
    const { data } = await api.put(`/newworkRequest`, info);
    return data;
  }
}
